<template>

  <!-- id : contents : (S) -->
  <div id='contents' class="st-login" >
    <!-- class : content-wrap : (S) -->
    <section class="content-wrap st-center">
      <!-- class : main-wrap (S) -->
      <div class="main-wrap">
        <div class="login-find-wrap">
          <div class="tab-box">
            <router-link to="/find/findId">아이디 찾기</router-link>
            <router-link to="/find/findPwdId" class="on">비밀번호 재설정</router-link>
          </div>
          <div class="find-form-box">
            <div class="row-box open st-long">
              <div class="select-tit-box">새 비밀번호를 입력해 주세요.</div>
              <div class="txt-box ">
                <div class="input-box">
                  <span class="s-tit">새 비밀번호</span>
                  <input name="NewPswd" type="password" v-model="newPswd" class="input-st1" @keyup="formatValidation" placeholder="8~15자의 영문,숫자,특수문자 조합으로 입력해 주세요.">
                </div>
                <div class="input-box ">
                  <span class="s-tit">새 비밀번호 확인</span>
                  <input name="ReNewPswd" type="password" v-model="reNewPswd" class="input-st1" @keyup="formatValidation" placeholder="비밀번호를 다시 한번 입력해 주세요.">
                </div>
                <span v-if="validation.newPswd != null" class="txt-error">{{validation.newPswd}}</span>
                <span v-else-if="validation.reNewPswd != null" class="txt-error">{{validation.reNewPswd}}</span>
              </div>
            </div>
          </div>
          <div class="btn-box"><a href="javascript:;" @click="onClickFindPwdResult" class="btn-submit" :style="styleObject">확인</a></div>
        </div>
      </div>
      <!-- class : main-wrap (E) -->


    </section>
    <!-- class : content-wrap : (E) -->
  </div>
  <!-- id : contents : (E) -->

</template>

<script>

import UserApi from "@/api/UserApi";

export default {
  name: "findPwdReset",
  data(){
    return {
      newPswd: '',
      reNewPswd: '',
      validation:{
        newPswd:'',
        reNewPswd:''
      },
      styleObject:{
        'background-color':'#666666',
        cursor:'default'
      }
    }
  },
  methods:{
    formatValidation(e){
      let passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/;

      if(e.target.name == 'NewPswd') {
        if (e.target.value === '') {
          this.validation.newPswd = '새 비밀번호를 입력해주세요.'
        } else if (e.target.value === this.pswd) {
          this.validation.newPswd = '기존에 사용중인 비밀번호입니다.'
        } else if (!passwordRegExp.test(this.newPswd)) {
          this.validation.newPswd = '비밀번호는 8~15자 영문, 숫자, 특수문자 조합으로 입력해주세요.'
        } else {
          this.validation.newPswd = null

          if (this.reNewPswd != '') {
            if(e.target.value !== this.reNewPswd) {
              this.validation.reNewPswd = '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해주세요.'
            }else {
              this.validation.reNewPswd = null
            }
          }
        }
      }else if(e.target.name == 'ReNewPswd') {
        if (e.target.value === '') {
          this.validation.reNewPswd = '새 비밀번호 확인을 입력해주세요.'
        } else if (e.target.value != this.newPswd) {
          this.validation.reNewPswd = '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해주세요.'
        } else {
          this.validation.reNewPswd = null
        }
      }
      this.onChangeActive()
    },
    onChangeActive() {
      if (this.validation.newPswd == null && this.validation.reNewPswd == null) {
        this.styleObject = {
          'background-color': '',
          cursor: 'pointer'
        }
      }else{
        this.styleObject = {
          'background-color':'#666666',
          cursor:'default'
        }
      }
    },
    onClickFindPwdResult(){
      if(this.validation.newPswd == null && this.validation.reNewPswd == null) {
        let param = new FormData();
        param.append('keyType', this.$route.params.keyType)
        param.append('key', this.$route.params.key)
        param.append('authNo', this.$route.params.authNo)
        param.append('password', this.newPswd)

        UserApi.resetPassword(this.$route.params.userId, param).then((data)=>{
          if(data) {
            this.$router.push(`/find/findPwdResult`)
          }else{
            alert('비밀번호 재설정 중 오류가 발생했습니다.')
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>